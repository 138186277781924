export default [
    {
        title: 'TopografiasPruebasProvocativas',
        load: () => import('./components/custom_templates/TopografiasPruebasProvocativas.vue')
    },
    {
        title: 'EcografiaPerfilTiroideo',
        load: () => import('./components/custom_templates/EcografiaPerfilTiroideo.vue')
    }
];
