import { defineAsyncComponent, markRaw } from 'vue';
import components from '../TemplatesComponents';

export default () => {
    const getComponent = async (title) => {
        try {
            const component = components.find((obj) => obj.title === title);

            const asyncComponente = defineAsyncComponent(() => component.load());
            return markRaw(asyncComponente);
        } catch (error) {
            console.log(error.message);
            return false;
        }
    };

    return {
        components,
        getComponent,
        getSelect() {
            return components.map((c) => ({ id: c.title, label: c.title }));
        },
    };
};
